import { Card, CardImage } from './styled';
import { CoreValuesCardProps } from 'global/globalTypes';

function CoreValuesCard({ image, background, alt, showZoomImage }: CoreValuesCardProps) {
    return (
        <Card>
            <CardImage
                src={image}
                placeholderSrc={background}
                alt={alt}
                loading="lazy"
                effect="blur"
                onClick={() => showZoomImage!(image)}
            />
        </Card>
    );
}

export default CoreValuesCard;
