import { put, takeEvery } from 'redux-saga/effects';
import { sendEmail } from 'api'; // I'm gonna change it
import {
    getEmailFetch,
    getEmailSuccess,
    getEmailFailure,
} from 'store/states/email';

const tempData = {
    first_name: 'temp',
    last_name: 'temp',
    email: 'temp',
    phone_number: 'temp',
    how_did_you_hear_about_us: 'temp',
    message: 'temp',
};

function* fetchDistributors() {
    try {
        const { data } = yield sendEmail(tempData);
        yield put(getEmailSuccess(data.items));
    } catch (error) {
        yield put(getEmailFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(getEmailFetch.type, fetchDistributors);
}
