import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import externalIcon from 'assets/icons/external.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface HamburgerMenuIconProps {
    isOpen: boolean;
}

export const HamburgerMenuContainer = styled.div``;

export const HamburgerLogo = styled.img`
    width: 180px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 20px;
`;

export const HamburgerMenuIcon = styled.div<HamburgerMenuIconProps>`
    position: relative;
    display: inline-block;
    cursor: pointer;

    .bar1,
    .bar2,
    .bar3 {
        width: 30px;
        height: 3px;
        background-color: #ffffff;
        margin: 6px 0;
        transition: 0.4s;
    }

    ${(props) =>
        props.isOpen &&
        `
        .bar1 {
          transform: translate(0, 11px) rotate(-45deg);
        }
    
        .bar2 {
          opacity: 0;
        }
    
        .bar3 {
          transform: translate(0, -7px) rotate(45deg);
        }
      `}
`;

export const CircleHamburgerMenuContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
`

export const CircleHamburgerMenuIcon = styled.div<HamburgerMenuIconProps>`
    position: relative;
    display: inline-block;
    cursor: pointer;
    z-index: 2;
    border: 1px solid #9E9E9E;
    border-radius: 50%;
    padding: 10px;

    .bar1,
    .bar2,
    .bar3 {
        width: 30px;
        height: 3px;
        background-color: white;
        margin: 6px 0;
        transition: 0.4s;
    }

    ${(props) =>
        props.isOpen &&
        `
        .bar1 {
          transform: translate(0, 9px) rotate(-45deg);
        }
    
        .bar2 {
          opacity: 0;
        }
    
        .bar3 {
          transform: translate(0, -9px) rotate(45deg);
        }
      `}
`;

export const MenuWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-weight: 100vh;
    overflow-y: auto;
    background: rgba(31, 31, 31, 0.3);

    .is-external:after {
        position: relative;
        content: '';
        width: 18px;
        height: 18px;
        top: 4px;
        left: 4px;
        background: url(${externalIcon}) no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
    }

    @media screen and (max-width: 600px) {
        overflow-x: scroll;
    }
`;

export const MenuLogoBlock = styled.div`
    width: 100%;
    background: #353535;
`

export const MenuContainer = styled.div`
    text-align: left;
    background: #353535;
    padding: 0 20px;
`;

export const MenuBoxes = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const MenuBox = styled.div`
    margin-top: 20px;
    width: calc(25% - 6px);

    @media screen and (max-width: 800px) {
        width: calc(25% - 6px);

    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const MenuBanner = styled(LazyLoadImage)`
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
`;

export const MenuTitle = styled.p`
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    color: #4F7FA7;
    margin-top: 16px;
    margin-bottom: 0;
`;

export const MenuSubtitle = styled(NavLink)`
    display: block;
    color: inherit;
    margin: 6px 0;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #a3c202;
    }
`;