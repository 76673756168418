import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

interface ProductsDataProps {
    isLoading: boolean;
    products: {
        total_page_count: number,
        data: any[]
    };
    productById: any;
    error: AxiosError | Error | null;
}

const initialState: ProductsDataProps = {
    isLoading: false,
    products: {
        total_page_count: 0,
        data: []
    },
    productById: {},
    error: null,
};

export const productSlice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
        getProductsFetch: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = true;
            state.error = null;
        },
        getProductsSuccess: (state, { payload }: PayloadAction<any>) => {
            state.products = payload;
            state.isLoading = false;
        },
        getProductsFailure: (state, { payload }: PayloadAction<any>) => {
            state.error = payload;
            state.isLoading = false;
        },

        getProductByIdFetch: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = true;
            state.error = null;
        },
        getProductByIdSuccess: (state, { payload }: PayloadAction<any>) => {
            state.productById = payload;
            state.isLoading = false;
        },
        getProductByIdFailure: (state, { payload }: PayloadAction<any>) => {
            state.error = payload;
            state.isLoading = false;
        },
    },
});

export const {
    getProductsFetch, getProductsSuccess, getProductsFailure,
    getProductByIdFetch, getProductByIdSuccess, getProductByIdFailure
} = productSlice.actions;

export default productSlice.reducer;
