import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Poppins;
    font-size: 13px;
    font-family: sans-serif;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0px 20px;
  }

  main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
  }

  button {
    border: none;
  }
  
  button:hover {
    transform: scale(0.95);
  }

  .primary-button {
    padding: 8px 56px;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    background: #A3C202;
    border-radius: 8px;
    border: none;
    margin: 16px 0 36px 0;
    cursor: pointer;
  }

  .input-error {
    border: 1px solid #fc8181;
  }

  .error-message {
      color: #fc8181;
      padding 0 0 10px 10px;
  }

  .skip-to-main-content {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
  }

  .skip-to-main-content:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke-width: 2px;
  }
`;
