import styled from 'styled-components';

interface FooterProps {
    isFooter?: boolean;
}

export const ContactUsFormContainer = styled.div<FooterProps>`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;

    .split {
        display: flex;
        gap: 25px;
    }

    @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: ${(props) => (props.isFooter ? '0' : '0 20px')};
    }
`;

export const ContactUsFormDevider = styled.div<FooterProps>`
    width: ${(props) => props.isFooter ? '100%' : '50%'};
`;

export const ContactUsButtonBlock = styled.div`
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
        justify-content: center;
    }
`;

export const ContactUsButton = styled.button`
    padding: 8px 56px;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1f1f1f;
    background: #a3c202;
    border-radius: 8px;
    border: none;
    margin: 16px 0 36px 0;
    cursor: pointer;

    &:disabled {
        opacity: 0.35;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;
