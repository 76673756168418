import { useEffect, Suspense } from 'react';
import {
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Header, SubHeader, Footer, Loader } from './components';
import { IRoute, routes, IRedirect, redirects } from 'routes';

import { useDispatch } from 'react-redux'
import { setUser } from 'store/states';

import { Helmet, HelmetProvider } from 'react-helmet-async';

// import AOS from 'aos';
// import 'aos/dist/aos.css';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const renderRoute = ({ url, Component }: IRoute): JSX.Element => (
        <Route key={url} path={url} element={<Component />} />
    );

    const redirectRoute = ({ url, to }: IRedirect): JSX.Element => (
        <Route key={url} path={url} element={<Navigate to={to} />} />
    );

    // useEffect(() => {
        // AOS.init();
    // }, []);

    useEffect(() => {
        dispatch(setUser(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null))
    }, [dispatch])

    // additional rerenders
    useEffect(() => {
        if (location.pathname === '/details#faq') {
            window.location.href = 'https://blog.profound-tech.com/';
        }

        if (
            location.pathname === '/support' ||
            location.pathname === '/support/' ||
            location.pathname === '/supportPortal/supportPortal' ||
            location.pathname === '/supportPortal/supportPortal/'
        ) {
            window.location.href = 'https://support.profound-tech.com/';
        }

        const terms = location.pathname === '/docs/terms.pdf/' || location.pathname === '/docs/terms.pdf' 
            || location.pathname === '/terms' || location.pathname === '/terms/'
        if (terms) {
            window.location.href = '/static/docs/terms.pdf'
        }

        if (location.pathname === '/terms-continued' || location.pathname === '/terms-continued/') {
            window.location.href = '/static/docs/terms-continued.pdf'
        }

        if (location.pathname === '/customer-responsibilities' || location.pathname === '/customer-responsibilities/') {
            window.location.href = '/static/docs/customer-responsibilities.pdf'
        }

        if (location.hash === '#gallery') {
            navigate('/design-hub');
        }

        if (location.hash === '#contact') {
            navigate('/contact-us');
        }
    }, [location, navigate]);
    
    const helmetContext = {};

    return (
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <link rel="canonical" href={`https://profound-tech.com${location.pathname}`} />
            </Helmet>
            <main id="main">
                <a href="#main" className="skip-to-main-content">
                    Skip to main content
                </a>
                <Header />
                <SubHeader />
                <Suspense fallback={<Loader />}>
                    <Routes>
                        {routes.map(renderRoute)}
                        {redirects.map(redirectRoute)}
                    </Routes>
                </Suspense>
                <Footer />
            </main>
        </HelmetProvider>
    );
}

export default App;
