import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CardProps {
    hover: boolean;
}

export const Card = styled(NavLink)`
    text-decoration: none;
`;

export const CardContainer = styled.div<CardProps>`
    position: relative;
    height: 280px;
    cursor: pointer;
    border-radius: 8px;
    width: 430px;
    // opacity: ${(props) =>  props.hover ? '1' : '0.5'};

    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const CardImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
`;

export const CardTitle = styled.p<CardProps>`
    font-family: Poppins;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    color: #4f7fa7;
    font-size: 20px;

    // color: ${(props) => props.hover ? '#4f7fa7' : '#9E9E9E'};
    // font-size: ${(props) => props.hover ? '25px' : '20px'};
`;
