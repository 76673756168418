import styled from 'styled-components';

export const Image = styled.img`
    border-radius: 8px;
    width: 350px;
    height: 100px;
    object-fit: contain;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;
