import {
    PeoplePassionContainer,
    PeoplePassionGrid,
    PeoplePassionImageWrapper,
    PeoplePassionImage
} from './styled';

function PeoplePassion({ item, showZoomImage }: any) {
    return (
        <PeoplePassionContainer id="our-passion">
            <PeoplePassionGrid>
                {item.map((m: any) => (
                    <PeoplePassionImageWrapper className={m.className}>
                        <PeoplePassionImage
                            loading="lazy"
                            key={m.id}
                            src={m.image}
                            alt={m.alt}
                            width={'100%'}
                            height={'100%'}
                            onClick={() => showZoomImage(m.image)}
                            effect='blur'
                            placeholderSrc={m.background}
                        />
                    </PeoplePassionImageWrapper>
                ))}
            </PeoplePassionGrid>
        </PeoplePassionContainer>
    );
}

export default PeoplePassion;
