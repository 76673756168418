import styled from 'styled-components';

interface SubscribeProps {
    isFooter: boolean;
}

export const SubscribeContainer = styled.div<SubscribeProps>`
    margin: ${(props) => (props.isFooter ? '0' : '20px 0 60px 0')};
`;

export const SubscribeTitle = styled.p<SubscribeProps>`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: ${(props) => (props.isFooter ? '16px' : '20px')};
    line-height: ${(props) => (props.isFooter ? '24px' : '30px')};
    text-align: center;
    margin-bottom: ${(props) => (props.isFooter ? '16px' : '4px')};
    color: ${(props) => (props.isFooter ? 'white' : '#626262')};
`;

export const SubscribeInputContainer = styled.div`
    width: 450px;
    display: flex;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
        width: 80%;
    }
`;

export const SubscribeInputBlock = styled.div<SubscribeProps>`
    padding: 6px 16px;
    background: ${(props) => (props.isFooter ? '#326fa399' : '#E0E0E080')};
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    border-radius: 8px 0 0 8px;
`;

export const SubscribeInputSpan = styled.span`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #a3c202;
    margin-right: 5px;
`;

export const SubscribeInput = styled.input<SubscribeProps>`
    background: transparent;
    width: fit-content;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => (props.isFooter ? '#e0e0e0' : 'black')};
    outline: none;
    flex: 1;
    border: none;

    &::placeholder {
        color: ${(props) => (props.isFooter ? 'white' : 'black')};
    }
`;

export const SubscribeButton = styled.button`
    background: #a3c202;
    border-radius: 0px 8px 8px 0px;
    height: 40px;
    border: 1px solid #a3c202;
    width: 74px;
`;
