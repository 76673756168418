import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthProps {
    isSignInLoading: boolean;
    signInData: {};
    signInError: any;
    isSignUpLoading: boolean;
    isSignUpSuccess: boolean;
    signUpError: any;
}

const initialState: AuthProps = {
    isSignInLoading: false,
    signInData: {},
    signInError: null,
    isSignUpLoading: false,
    isSignUpSuccess: true,
    signUpError: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        signInFetch: (state, action) => {
            state.isSignInLoading = true;
            state.signInError = false;
        },
        signInSuccess: (state) => {
            state.signInData = true;
            state.isSignInLoading = false;
            state.signInError = false;
        },
        signInFailure: (state, { payload }: PayloadAction<any>) => {
            state.signInError = payload;
            state.isSignInLoading = false;
        },
        
        signUpFetch: (state, action) => {
            state.isSignUpLoading = true;
            state.signUpError = false;
        },
        signUpSuccess: (state, { payload }: PayloadAction<any>) => {
            state.isSignUpSuccess = payload;
            state.isSignUpLoading = false;
            state.signUpError = false;
        },
        signUpFailure: (state, { payload }: PayloadAction<any>) => {
            state.signUpError = payload;
            state.isSignUpLoading = false;
        },
    },
});

export const { 
    signInFetch, signInSuccess, signInFailure,
    signUpFetch, signUpSuccess, signUpFailure
} = authSlice.actions;

export default authSlice.reducer;
