import { jwtDecode } from 'jwt-decode'

export const LOCAL_STORAGE_TOKEN_KEY = 'token'

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

export const removeToken = () => localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)

export const setToken = (token: string) =>
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)

export const decodeToken = (token: string) => jwtDecode(token)

export const checkIfTokenValid = () => {
  const token = getToken()

  if (!token) {
    return false
  }
  
  const user: any = decodeToken(token)

  // temporary
  // return user.exp > new Date().getTime() / 1000
  return token ? true : false

}