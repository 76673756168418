import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from './states/auth';
import emailReducer from './states/email';
import productReducer from './states/products';
import featuredProductReducer from './states/featuredProducts';
import productReviewFiltersReducer from './states/productReviewFilters';
import roomSurveyReducer from './states/roomSurvey';
import userReducer from './states/user';
import rootSagas from './sagas';

const saga = createSagaMiddleware();
export const store = configureStore({
    reducer: {
        auth: authReducer,
        emails: emailReducer,
        products: productReducer,
        featuredProducts: featuredProductReducer,
        productReviewFilters: productReviewFiltersReducer,
        roomSurvey: roomSurveyReducer,
        user: userReducer
    },
    middleware: [saga],
});
saga.run(rootSagas);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
