import { useState, useEffect, useRef } from 'react';
import { Modal } from 'components';

interface EnlargePostImageProps {
  isOpen: boolean;
  closeModal: () => void;
  imageUri: string;
}

const EnlargePostImage = ({ isOpen, closeModal, imageUri }: EnlargePostImageProps) => {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageStyle, setImageStyle] = useState({});
  let imageRef: any= useRef(null)

  useEffect(() => {
    // Get image size to calculate the modal size
    if (imageRef.current && imageWidth === 0 && imageHeight === 0) {
      setImageWidth(imageRef.current?.naturalWidth);
      setImageHeight(imageRef.current?.naturalHeight);
    }
    // Case 2 - square image
    setImageStyle({
      width: window.innerHeight * 0.7 - 20,
      height: window.innerHeight * 0.7 - 25,
    });
    
    // Calculate height to ensure that if the image is vertical, the modal shouldn't be more than 70% of the screen
    // Taking into account the inner padding of the modal

    if (imageHeight > imageWidth) {
      // Case 1 - vertical image
      setImageStyle({
        width: (imageWidth / imageHeight) * (window.innerHeight * 0.7 - 20),
        height: window.innerHeight * 0.7 - 25,
      });
    } else if (imageHeight === imageWidth) {
      // Case 2 - square image
      setImageStyle({
        width: window.innerHeight * 0.7 - 20,
        height: window.innerHeight * 0.7 - 25,
      });
    } else {
      // Case 3 - horizontal image
      setImageStyle({
        width: window.innerWidth - 20,
        maxWidth: '900px',
      });
    }

  }, [imageWidth, imageHeight]);


  return ( 
    <Modal 
      isOpen={isOpen} 
      closeModal={closeModal} 
      padding='24px' 
      borderRadius='5px'
      colorCloseButton='black'
    >
      <img 
        src={imageUri} 
        ref={instance => (imageRef = instance)} 
        style={{
          ...imageStyle,
          objectFit: 'contain'
        }} 
        alt="zoom" 
      />
    </Modal> 
  );
};

export default EnlargePostImage;
