import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface SubHeaderProps {
    isDisabled?: boolean;
    isSelected?: boolean;
}

export const SubHeaderLink = styled(NavLink)<SubHeaderProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #000000;
    width: 120px;

    opacity: ${(props) => (props.isDisabled ? '0.2' : '1')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
`;

export const SubHeaderButton = styled.div<SubHeaderProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
    width: 120px;

    opacity: ${(props) => (props.isDisabled ? '0.2' : '1')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
`;

export const SubHeaderTitle = styled.p<SubHeaderProps>`
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: ${(props) => (props.isSelected ? 'rgb(163, 194, 2)' : 'black')};
    font-weight: ${(props) => (props.isSelected ? '500' : '400')};
`;