import styled from 'styled-components';

export const ErrorBoundaryContainer = styled.div``;

export const Title = styled.h1`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 38px;
    line-height: 40px;
    color: #0e4574;
    margin-top: 100px;
    text-align: center;

    @media screen and (max-width: 600px) {
        font-size: 26px;
    }
`;

export const Error = styled.p`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
    text-align: center;

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
`;

export const ReloadButton = styled.button`
    border: 1px solid #a3c202;
    color: #a3c202;
    border-radius: 8px;
    background: transparent;
    padding: 8px 20px;
    font-family: 'Poppins';
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
`;

export const HomeButton = styled.button`
    border: 1px solid #a3c202;
    color: black;
    border-radius: 8px;
    background: #a3c202;
    padding: 8px 20px;
    font-family: 'Poppins';
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
`;
