import styled from "styled-components"

interface ModalContainerProps {
    isBlur?: boolean
}

interface ModalContentProps {
    padding: string,
    borderRadius: string,
}


export const ModalContainer = styled.div<ModalContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(${(props) => props.isBlur ? '22.5px' : '0'});
`

export const ModalContent = styled.div<ModalContentProps>`
    padding: ${(props) => props.padding};
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.1);
    border-radius: ${(props) => props.borderRadius};
    background: #fff;
    position: relative;

    @media screen and (max-width: 800px) {
        width: 90%;
        height: 85%;

        img:nth-child(2) {
            width: 100% !important;
            height: unset !important;
        }
    }
`

export const ModalCloseButton = styled.img`
    position: absolute;
    top: 2px;
    right: 6px;
    cursor: pointer;
`