import { put, takeEvery } from 'redux-saga/effects';
import { products, productById } from 'api';
import {
    getProductsFetch, getProductsSuccess, getProductsFailure,
    getProductByIdFetch, getProductByIdSuccess, getProductByIdFailure
} from 'store/states/products';
import { AxiosError } from 'axios';

function* fetchProducts({ payload }: any) {
    try {
        const { data } = yield products({ labelId: payload.labelId, page: payload.page });
        data.data.sort((a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
        yield put(getProductsSuccess(data));
    } catch (error) {
        yield put(getProductsFailure(error));
    }
}

function* fetchProductById({ payload }: any) {
    try {
        const { data } = yield productById(payload);
        yield put(getProductByIdSuccess(data));
    } catch (error) {
        if ((error as AxiosError).response?.data && (error as AxiosError).response?.status) {
            const errorData = {
                ...(error as AxiosError).response!.data!,
                status: (error as AxiosError).response!.status
            }
            yield put(getProductByIdFailure(errorData));
        } else {
            yield put(getProductByIdFailure(error));
        }
    }
}

export default function* rootSaga() {
    yield takeEvery(getProductsFetch.type, fetchProducts);
    yield takeEvery(getProductByIdFetch.type, fetchProductById);
}
