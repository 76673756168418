import { useState } from 'react';
import {
    Card,
    CardContainer,
    CardImage,
    CardTitle,
    CardDescription,
} from './styled';
import { DesignHubCardProps } from 'global/globalTypes';

function DesignHubCard({
    image,
    background,
    title,
    description,
    alt,
}: DesignHubCardProps) {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(true);
    };

    const onLeave = () => {
        setHover(false);
    };

    return (
        <Card to="/downloads">
            <CardContainer
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
                hover={description.length > 0 ? hover : false}
            >
                <CardImage
                    src={image}
                    alt={alt}
                    loading="lazy"
                    effect="blur"
                    placeholderSrc={background}
                    width={'100%'}
                    height={'100%'}
                />
                {hover ? (
                    <CardDescription>{description}</CardDescription>
                ) : null}
            </CardContainer>
            <CardTitle>{title}</CardTitle>
        </Card>
    );
}

export default DesignHubCard;
