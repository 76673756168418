import { Span, Textarea } from './styled';
import { useRef } from 'react';
import { useField } from 'formik';

function TextareaField({ label, isFooter, ...props }: any) {
    const [field, meta] = useField(props);
    const inputRef = useRef<HTMLInputElement | null>(null) 

    const removePlaceholder = () => {
        if (inputRef.current) {
            inputRef.current.placeholder = '';
        }
    }

    return (
        <>
            {label ? <Span isFooter={isFooter}>{label}</Span> : null}
            <Textarea
                ref={inputRef}
                {...field}
                {...props}
                isFooter={isFooter}
                className={meta.touched && meta.error ? 'input-error' : ''}
                onFocus={removePlaceholder}
            />
            {meta.touched && meta.error && (
                <div className="error-message">{meta.error}</div>
            )}
        </>
    );
}

export default TextareaField;
