import {
    HistoryContainer,
    HistoryBanner,
    HistoryContent,
    HistoryTitle,
    HistoryDesc,
    HistoryYear,
} from './styled';
import { useMediaQuery } from 'utils/media-query';

interface HistoryProps {
    year: string;
    title: string;
    desc: string;
    banner?: string;
    background?: string;
    alt: string;
    isVideo?: boolean;
}

function History({
    year,
    title,
    desc,
    banner,
    background,
    alt,
}: HistoryProps) {
    const isMobile = useMediaQuery('mobile');
    return (
        <HistoryContainer>
            <HistoryBanner
                src={banner}
                placeholderSrc={background}
                alt={alt}
                effect="blur"
                width={isMobile ? 'auto' : '800px'}
                height={isMobile ? 'auto' : '45vh'}
            />
            <HistoryYear>{year}</HistoryYear>
            <HistoryContent>
                <HistoryTitle>{title}</HistoryTitle>
                <HistoryDesc>{desc}</HistoryDesc>
            </HistoryContent>
        </HistoryContainer>
    );
}

export default History;
