import { put, takeEvery } from 'redux-saga/effects';
import { signIn as signInQuery, signUp as signUpQuery } from 'api'; // I'm gonna change it
import {
    signInFetch,
    signInSuccess,
    signInFailure,
    signUpFetch,
    signUpSuccess,
    signUpFailure
} from 'store/states/auth';
import { setUser } from 'store/states/user';
import { decodeToken, setToken } from 'utils/token';

function* signIn({ payload }: any) {
    try {
        const { data } = yield signInQuery(payload);
        setToken(data.access)
        const decodedToken: any = decodeToken(data.access)
        const userData = {
            access: data.access,
            refresh: data.refresh,
            username: payload.username,
            expiresIn: decodedToken.exp * 1000,
            id: decodedToken.user_id
        }
        localStorage.setItem('user', JSON.stringify(userData))
        yield put(signInSuccess());
        yield put(setUser(userData))
    } catch (error) {
        yield put(signInFailure(error));
    }
}

function* signUp({ payload }: any) {
    try {
        const { status } = yield signUpQuery(payload);
        if (status === 201) {
            yield put(signUpSuccess(true));
        }
    } catch (error) {
        yield put(signUpFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(signInFetch.type, signIn);
    yield takeEvery(signUpFetch.type, signUp);
}
