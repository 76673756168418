import styled from 'styled-components';

interface CardProps {
    source?: string;
    textAlign?: string
}

export const Card = styled.div<CardProps>`
    width: 100%;
    height: 434px;
    cursor: pointer;
    border-radius: 8px;
    background: ${(props) => props.source ? `url(${props.source})` : null};
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardContainer = styled.div<CardProps>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.textAlign === 'center' ? 'center' : props.textAlign === 'left' ? 'flex-start' : 'flex-end'};
`;

export const CardTitle = styled.p<CardProps>`
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    text-align: ${(props) => props.textAlign ? props.textAlign : 'unset'};
    color: white;
    margin-top: 16px;
    margin-bottom: 26px;
`;

export const CardSubtitle = styled.p<CardProps>`
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: ${(props) => props.textAlign ? props.textAlign : 'unset'};
    color: white;
    width: 55%;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`