import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

export const HistoryContainer = styled.div`
    background: linear-gradient(90deg, transparent 12%, #e0e0e0 10%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 120px;
    margin-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 50px;
    border-radius: 16px;
    width: 88%;

    @media screen and (max-width: 600px) {
        width: 100%;
        background: linear-gradient(90deg, transparent 2%, #e0e0e0 10%);
        margin-left: 0px;
    }
`;

export const HistoryBanner = styled(LazyLoadImage)`
    margin-top: -80px;
    width: 100%;
    height: 110%;
    border-radius: 16px;
    object-fit: cover;
    position: relative;
    margin-bottom: 40px;
    max-height: 500px;

    @media screen and (max-width: 600px) {
        max-width: 90%;
        height: 300px;
        margin-bottom: 20px;
    }
`;

export const HistoryContent = styled.div`
    margin-left: 18%;
    margin-right: 5%;

    @media screen and (max-width: 600px) {
        margin-left: 10%;
    }
`;

export const HistoryYear = styled.p`
    position: absolute;
    top: 60px;
    left: 90px;
    font-family: Poppins;
    font-weight: 700;
    font-size: 24px;
    color: white;

    @media screen and (max-width: 600px) {
        left: 40px;
    }
`;

export const HistoryTitle = styled.p`
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
    color: #4f7fa7;
    margin-bottom: 15px;
`;

export const HistoryDesc = styled.p`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #626262;
    margin-bottom: 0;
`;
