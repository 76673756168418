import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EmailDataProps {
    isLoading: boolean;
    data: any[];
    error: Error | null;
}

const initialState: EmailDataProps = {
    isLoading: false,
    data: [],
    error: null,
};

export const emailSlice = createSlice({
    name: 'emails',
    initialState: initialState,
    reducers: {
        getEmailFetch: (state) => {
            state.isLoading = true;
        },
        getEmailSuccess: (state, { payload }: PayloadAction<any[]>) => {
            state.data = payload;
            state.isLoading = false;
        },
        getEmailFailure: (state, { payload }: PayloadAction<any>) => {
            state.error = payload;
            state.isLoading = false;
        },
    },
});

export const { getEmailFetch, getEmailSuccess, getEmailFailure } =
    emailSlice.actions;

export default emailSlice.reducer;
