import styled from 'styled-components';

export const SupportTechContainer = styled.div`
    overflow: hidden;
    box-sizing: border-box;
    margin-bottom: 50px;
`;

export const SupportTechTitle = styled.h2`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 30px;
    color: #0e4574;
    margin: 40px 0 40px 0;

    @media screen and (max-width: 600px) {
        margin-top: 10px;
    }
`;
