import { useRef, useEffect } from 'react'
import { ModalContainer, ModalContent, ModalCloseButton } from './styled'
import { useOutsideClick } from 'customHooks/useOutsideClick';

import closeIconWhite from 'assets/icons/close-icon-white.svg'
import closeIconBlack from 'assets/icons/close-icon-black.svg'

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    children: any;
    padding: string,
    borderRadius: string,
    colorCloseButton: string,
    isBlur?: boolean
}

function Modal({ isOpen, closeModal, children, padding, borderRadius, colorCloseButton, isBlur }: ModalProps) {
    const modalRef = useRef(null);
    useOutsideClick(modalRef, closeModal)

    // disable scroll when modal is open
    useEffect(() => {
        if (isOpen) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'unset';
    }, [isOpen]);

    return (
        isOpen ? 
            <ModalContainer isBlur={isBlur}>
                <ModalContent ref={modalRef} 
                    padding={padding}
                    borderRadius={borderRadius}
                >
                    <ModalCloseButton 
                        src={colorCloseButton === 'white' ? closeIconWhite : closeIconBlack}
                        alt='close-icon'
                        onClick={closeModal}
                        color={colorCloseButton}
                    />
                    {children}
                </ModalContent>
            </ModalContainer>
        : null
    )
}

export default Modal