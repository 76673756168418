import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userProps {
    id: string,
    access: string,
    refresh: string,
    username: string,
    expiresIn: number,
}

const initialState: userProps | {} = {
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state, { payload }: PayloadAction<userProps | {}>) => {
            Object.assign(state, payload);
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
