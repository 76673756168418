import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import externalIcon from 'assets/icons/external.svg';

export const NavContainer = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    // width: 100%;

    .is-external:after {
        position: relative;
        content: '';
        width: 18px;
        height: 18px;
        top: 3px;
        left: 6px;
        background: url(${externalIcon}) no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
    }

    .active {
        color: #a3c202;
    }

    a:hover {
        color: #a3c202;
    }
`;

export const NavLi = styled.li`
    position: relative;
    font-size: 16px;
    font-family: 'Poppins';

    div {
        cursor pointer;
    }
`;

export const NavLinkWrap = styled(NavLink)`
    display: block;
    font-size: inherit;
    color: inherit;
    // color: #4F7FA7;
    text-decoration: none;
    text-align: left;
    padding: 0.7rem 1rem;

    @media screen and (max-width: 1024px) {
        padding: 10px 18px 0 0;
    }
`;
