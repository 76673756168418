import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CardProps {
    hover: boolean;
}

export const Card = styled(NavLink)`
    text-decoration: none;
`;

export const CardContainer = styled.div<CardProps>`
    position: relative;
    width: 386px;
    height: 320px;
    cursor: pointer;
    border-radius: 8px;

    background: ${(props) =>
        props.hover
            ? 'linear-gradient(180deg, rgba(31, 31, 31, 0.65) 0%, rgba(31, 31, 31, 0.65) 100%)'
            : 'unset'};

    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const CardImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
    border-radius: 8px;
`;

export const CardTitle = styled.p`
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    text-align: left;
    color: #4f7fa7;
    margin-top: 16px;
`;

export const CardDescription = styled.p`
    font-family: Poppins;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 30px;
    background: linear-gradient(
        180deg,
        rgba(31, 31, 31, 0.65) 0%,
        rgba(31, 31, 31, 0.65) 100%
    );
    height: 100%;
    border-radius: 8px;
`;
