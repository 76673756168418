import {
    SubHeaderLink,
    SubHeaderTitle,
    SubHeaderButton
} from './styled';
import { setTagFilter } from 'store/states';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { SubHeaderCardProps } from 'global/globalTypes';

function SubHeaderCard({
    index,
    url,
    isExternal,
    isDisabled,
    isFilter,
    title,
    onClick,
    img
}: SubHeaderCardProps) {
    const dispatch = useDispatch()

    const { tag } = useSelector((state: RootState) => state.productReviewFilters);

    const setFilterTag = (isFilter: boolean | undefined, title: string, onClick: any) => {
        if (isFilter) {
            if (tag) dispatch(setTagFilter('')) 
            else dispatch(setTagFilter(title))
        } else {
            onClick()
        }
    }

    return (
        url ? (
            <SubHeaderLink
                key={index}
                to={url}
                target={isExternal ? '_blank' : '_self'}
                isDisabled={isDisabled}
            >
                <img src={img} alt="qweq-we" loading="lazy" />
                <SubHeaderTitle>{title}</SubHeaderTitle>
            </SubHeaderLink>
        ) : (
            <SubHeaderButton
                key={index}
                onClick={() => setFilterTag(isFilter, title.toLowerCase(), onClick)}
                isDisabled={isDisabled}
            >
                <img src={img} alt="qweq-we" loading="lazy" />
                <SubHeaderTitle isSelected={title.toLowerCase() === tag}>{title}</SubHeaderTitle>
            </SubHeaderButton>
        )
    );
}

export default SubHeaderCard;
