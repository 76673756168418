import { Image } from './styled';

interface ImagesProps {
    img: string;
    alt: string;
}

function TechSupport({ img, alt }: ImagesProps) {
    return <Image src={img} alt={alt} loading="lazy" />;
}

export default TechSupport;
