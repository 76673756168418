import {
    Card,
    CardContainer,
    CardTitle,
    CardSubtitle
} from './styled';
import { MarketingOpportunitiesProps } from 'global/globalTypes';

function MarketingOpportunities({
    background,
    title,
    description,
    textAlign,
}: MarketingOpportunitiesProps) {

    return (
        <Card source={background}>
            <CardContainer className='container' source={background} textAlign={textAlign}>
                <CardTitle textAlign={textAlign}>{title}</CardTitle>
                <CardSubtitle textAlign={textAlign}>{description}</CardSubtitle>
            </CardContainer>
        </Card>
    );
}

export default MarketingOpportunities;
