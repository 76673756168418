import styled from 'styled-components';

interface FooterProps {
    isFooter?: boolean;
}

export const InputContainer = styled.div<FooterProps>`
    padding: 6px 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px;
    background: ${(props) => (props.isFooter ? '#326fa399' : `#e0e0e080`)};
`;

export const Input = styled.input<FooterProps>`
    background: transparent;
    width: fit-content;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #e0e0e0;
    color: ${(props) => (props.isFooter ? '#e0e0e0' : `black`)};
    flex: 1;
    border: none;
    outline: none;

    &::placeholder {
        color: ${(props) => (props.isFooter ? 'white' : `gray`)};
    }
`;

export const Span = styled.span<FooterProps>`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px;
    color: ${(props) => (props.isFooter ? '#a3c202' : `#0e4574`)};
`;
