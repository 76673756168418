import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';

export const ProductBlock = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
    }
`;

export const ProductImage = styled(LazyLoadImage)`
    border-radius: 16px;
    cursor: pointer;
    
    @media screen and (max-width: 600px) {
        width: 100% !important;
        margin-top: 20px;
    }
`;

export const ProductContent = styled.div`
    width: 50%;
    text-align: left;
    margin-left: 60px;

    @media screen and (max-width: 600px) {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }
`;

export const ProductContentTitle = styled(NavLink)`
    display: block;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: #4f7fa7;
    text-decoration: none;
`;

export const ProductContentInfo = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ababab;
    margin-right: 40px;
`;

export const ProductContentDescription = styled.p`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #626262;
    margin-bottom: 12px;
    margin-top: 8px;
    width: 80%;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const ProductMoreLink = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: #a3c202;
    text-decoration: underline;
    width: fit-content;
    margin-top: 16px;
    cursor: pointer;
`;