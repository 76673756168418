import axios from 'axios';
import { endpoints } from './endpoints';

const axiosInstance = axios.create({
    headers: {
        'content-type': 'application/json',
    },
});

interface EmailProps {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    how_did_you_hear_about_us: string;
    message: string;
}

interface SurveyRoomProps {
    email: string,
    contactName: string,
    roomName: string,
    countRoom: number,
    roomDimensions: string,
    wallDimensions: string,
    displayCameraIsMounted: string,
    ceilingMaterial: string,
    ceilingDeckHeight: string,
    floorCabling: string,
    atTheTableOptions: string[],
    byodAtTheTable: string,
    additionalMicrophonesDesired: string,
    platformUsedInThisRoom: string[],
    schedulingPanel: string,
    acPowerAcknowledgement: string,
    customerResponsibleAcknowledgement: string,
    floorCoringAcknowledgement: string,
    interoperabilityAcknowledgement: string,
    customerProvidedEquipmentAcknowledgement: string,
    additionInformation: string,
}

interface SubscribeProps {
    email: string
}

interface AuthProps {
    username: string,
    email: string,
    password: string,
}


interface RefreshProps {
    refresh: string
}

export const sendEmail = (data: EmailProps) => {
    const url = endpoints.sendEmail;

    return axiosInstance.post(url, data);
};

export const subscribe = (data: SubscribeProps) => {
    const url = endpoints.subscribe;

    return axiosInstance.post(url, data);
};

export const products = ({ labelId, page }: { labelId: string, page: string }) => {
    const url = endpoints.products(labelId, page);

    return axiosInstance.get(url);
};

export const productById = (labelId: string) => {
    const url = endpoints.products(labelId);

    return axiosInstance.get(url);
};

export const featuredProducts = () => {
    const url = endpoints.featuredProducts;

    return axiosInstance.get(url);
};

export const surveyRoom = (data: SurveyRoomProps) => {
    const url = endpoints.surveyRoom;

    return axiosInstance.post(url, data);
};

export const signIn = (data: AuthProps) => {
    const url = endpoints.signIn;

    return axiosInstance.post(url, data);
};

export const signUp = (data: AuthProps) => {
    const url = endpoints.signUp;

    return axiosInstance.post(url, data);
};

export const tokenRefresh = (data: RefreshProps) => {
    const url = endpoints.tokenRefresh;

    return axiosInstance.post(url, data);
};