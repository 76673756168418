import styled from 'styled-components';

interface FooterProps {
    isFooter?: boolean;
}

export const Textarea = styled.textarea<FooterProps>`
    padding: 6px 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px;
    background: ${(props) => (props.isFooter ? '#326fa399' : `#e0e0e080`)};
    color: ${(props) => (props.isFooter ? '#a3c202' : `#0e4574`)};
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    width: 100%;
    border: none;
    resize: none;
    height: 80px;

    &::placeholder {
        color: ${(props) => (props.isFooter ? 'white' : `gray`)};
    }
`;

export const Span = styled.span<FooterProps>`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px;
    color: ${(props) => (props.isFooter ? '#a3c202' : `#0e4574`)};
`;
