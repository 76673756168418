import * as yup from 'yup';

export const contactUsSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup
        .string()
        .email('Please enter a valid email')
        .required('Required'),
    phoneNumber: yup.string().required('Required'),
    howDidYouHearAboutUs: yup.string().required('Required'),
    message: yup.string().required('Required'),
});
