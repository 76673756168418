import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle } from './global/globalStyle';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'utils/scrollToTop';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryLayout from 'components/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import { store } from './store';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module'

import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
    gtmId: 'GTM-W7CPJT'
}
 
TagManager.initialize(tagManagerArgs)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <GlobalStyle />
            <BrowserRouter>
                <ScrollToTop />
                <ErrorBoundary
                    FallbackComponent={ErrorBoundaryLayout}
                    onError={() =>
                        console.log('Here should be an error service log.')
                    }
                >
                    <App />
                </ErrorBoundary>
            </BrowserRouter>
            <ToastContainer />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
