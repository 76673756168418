import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HeaderContainer, HeaderWrapper, HeaderImage, PreviousPage } from './styled';
import { NavBar } from '../index';
import { HamburgerMenu } from '../index';
import { useMediaQuery } from 'utils/media-query';

import profoundWhiteLogo from 'assets/icons/white-logo.svg';
import profoundAgentLogo from 'assets/icons/agent-logo.svg';
import backArrow from 'assets/icons/white-back-arrow.svg'

function Header() {
    const [background, setBackground] = useState('transparent');

    const navigate = useNavigate();
    const location = useLocation();
    const isTablet = useMediaQuery('tablet');

    const isHomePage = location.pathname === '/';
    const isAgentPage = location.pathname === '/agent' || location.pathname === '/agent/';

    const listenScrollEvent = () => {
        if (window.scrollY > 50) {
            setBackground('#0E4574');
        } else {
            setBackground('transparent');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () => {
            window.removeEventListener('scroll', listenScrollEvent);
        };
    }, []);

    return (
        <HeaderWrapper background={!isHomePage ? '#0e4574' : background}>
            <HeaderContainer className="container">
                <HeaderImage
                    src={isAgentPage ? profoundAgentLogo : profoundWhiteLogo}
                    alt="profound-white-logo"
                    onClick={() => navigate('/')}
                    loading="lazy"
                />
                {isAgentPage ? 
                    <PreviousPage onClick={() => navigate(-1)}>
                        Back to Profound website
                        <img src={backArrow} alt='back-arrow' />
                    </PreviousPage>
                : 
                    <>
                        {isTablet ? null : <NavBar />}
                        <HamburgerMenu />
                    </>
                }
            </HeaderContainer>
        </HeaderWrapper>
    );
}

export default Header;
