import { useState } from 'react';
import {
    SubscribeContainer,
    SubscribeInputContainer,
    SubscribeTitle,
    SubscribeInputBlock,
    SubscribeInputSpan,
    SubscribeInput,
    SubscribeButton,
} from './styled';
import rightArrow from 'assets/icons/right-arrow.svg';
import { subscribe } from 'api';
import { toast } from 'react-toastify';

interface SubscribeProps {
    title: string;
    isFooter: boolean;
}

function Subscribe({ title, isFooter }: SubscribeProps) {
    const [email, setEmail] = useState('');
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async () => {
        if (email) {
            setLoading(true);
            try {
                await subscribe({ email } as any);
                toast.success('Email sent successfully!');
            } catch (error) {
                toast.error('Something wrong!');
            }
            setLoading(false);
        } else {
            toast.error('Fill the input!');
        }
    };

    return (
        <SubscribeContainer isFooter={isFooter}>
            <SubscribeTitle isFooter={isFooter}>{title}</SubscribeTitle>
            <SubscribeInputContainer>
                <SubscribeInputBlock isFooter={isFooter}>
                    <SubscribeInputSpan>Email</SubscribeInputSpan>
                    <SubscribeInput
                        type="text"
                        placeholder="johnsmith@gmail.com"
                        isFooter={isFooter}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </SubscribeInputBlock>
                <SubscribeButton onClick={onSubmit}>
                    {isLoading ? (
                        'Loading...'
                    ) : (
                        <img
                            src={rightArrow}
                            alt="right-arrow"
                            loading="lazy"
                        />
                    )}
                </SubscribeButton>
            </SubscribeInputContainer>
        </SubscribeContainer>
    );
}

export default Subscribe;
