import { FallbackProps } from 'react-error-boundary';
import {
    ErrorBoundaryContainer,
    Title,
    Error,
    Buttons,
    ReloadButton,
    HomeButton,
} from './styled';
import { useNavigate } from 'react-router-dom';

function ErrorBoundaryLayout({ error, resetErrorBoundary }: FallbackProps) {
    const navigate = useNavigate();
    return (
        <>
            <ErrorBoundaryContainer className="container">
                <Title>Something went wrong!</Title>
                <Error>{error.message}</Error>
                <Buttons>
                    <ReloadButton onClick={resetErrorBoundary}>
                        Reload Page
                    </ReloadButton>
                    <HomeButton onClick={() => navigate('/')}>
                        Home Page
                    </HomeButton>
                </Buttons>
            </ErrorBoundaryContainer>
        </>
    );
}

export default ErrorBoundaryLayout;
