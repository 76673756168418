import styled from 'styled-components'

export const AuthModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const AuthModalImage = styled.img`
    border-radius: 14px;
    width: 450px;
`

export const AuthModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
`

export const AuthModalInputBlock = styled.div`
    width: 80%;
    margin-bottom: 12px;
`

export const AuthModalInputLabel = styled.p`
    color: #0E4574;
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 6px;
`

export const AuthModalInput = styled.input`
    font-family: Poppins;
    width: 100%;
    border: none;
    padding: 4px 16px;
    border-radius: 4px;
    outline: none;
    border: 1px solid lightgrey;
`

export const AuthModalButton = styled.button`
    color: #1F1F1F;
    text-align: center;
    padding: 8px 20px;
    border-radius: 8px;
    background: #A3C202;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
    margin-bottom: 8px;
`

export const AuthModalText = styled.p`
    display: inline-flex;
    gap: 4px;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    margin-top: 12px;
`

export const AuthModalErrors = styled.div`
    color: #fc8181;
    max-width: 200px;
`

export const AuthModalLink = styled.p`
    color: #A3C202;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
`