import {
    Card,
    CardImageBox,
    CardImage,
    CardTitle,
    CardDescription,
} from './styled';
import { ServiceHubCardProps } from 'global/globalTypes';

function ServiceHubCard({
    image,
    background,
    title,
    description,
    alt,
}: ServiceHubCardProps) {
    return (
        <Card>
            <CardImageBox>
                <CardImage
                    src={image}
                    alt={alt}
                    loading="lazy"
                    effect="blur"
                    placeholderSrc={background}
                    width={'100%'}
                    height={'100%'}
                />
                <CardTitle>{title}</CardTitle>
            </CardImageBox>
            <CardDescription>{description}</CardDescription>
        </Card>
    );
}

export default ServiceHubCard;
