import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProductsDataProps {
    isLoading: boolean;
    data: any[];
    error: Error | null;
}

const initialState: ProductsDataProps = {
    isLoading: false,
    data: [],
    error: null,
};

export const featureProductSlice = createSlice({
    name: 'featuredProducts',
    initialState: initialState,
    reducers: {
        getFeaturedProductsFetch: (state) => {
            state.isLoading = true;
        },
        getFeaturedProductsSuccess: (state, { payload }: PayloadAction<any>) => {
            state.data = payload;
            state.isLoading = false;
        },
        getFeaturedProductsFailure: (state, { payload }: PayloadAction<any>) => {
            state.error = payload;
            state.isLoading = false;
        }
    },
});

export const { 
    getFeaturedProductsFetch, getFeaturedProductsSuccess, getFeaturedProductsFailure
} = featureProductSlice.actions;

export default featureProductSlice.reducer;
