import { all, fork } from 'redux-saga/effects';
import auth from './auth';
import email from './email';
import products from './products';
import featuredProducts from './featuredProducts';

export default function* rootSaga() {
    yield all([
        fork(auth),
        fork(email),
        fork(products),
        fork(featuredProducts)
    ]);
}
