import { useRef
    // , useState 
} from 'react';
import { useLocation } from 'react-router-dom';
import { NavContainer, NavLi, NavLinkWrap } from './styled';

import { menuItems } from 'global/globalState';
// import { checkIfTokenValid, removeToken } from 'utils/token';

// import { useDispatch } from 'react-redux';
// import { setUser } from 'store/states';

function NavBar() {
    // const isTokenValid = checkIfTokenValid()
    // const [customKey, setCustomKey] = useState('key')
    const menuItemRef = useRef(null);
    const { pathname } = useLocation();
    // const dispatch = useDispatch();

    // const removeUserData = () => localStorage.removeItem('user')

    // const logout = () => {
    //     removeToken();
    //     dispatch(setUser({}))
    //     removeUserData()
    //     setCustomKey(String(isTokenValid))
    // }
    
    return (
        <NavContainer>
        {/* key={customKey}> */}
            {menuItems.map((item, index) => (
                <NavLi ref={menuItemRef} key={index}>
                    <NavLinkWrap
                        // href={item.url}
                        to={item.url}
                        className={`${item.url === pathname ? 'active' : ''}  ${
                            item.isExternal && 'is-external'
                        }`}
                        target={item.isExternal ? '_blank' : '_self'}
                        rel="noreferrer"
                    >
                        {item.title}
                    </NavLinkWrap>
                </NavLi>
            ))}
            {/* {isTokenValid ?
                <NavLi ref={menuItemRef}>
                    <div
                        onClick={logout}
                    >
                        Logout
                    </div>
                </NavLi>
            : null} */}
        </NavContainer>
    );
}

export default NavBar;
