import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Card = styled.div`
    width: 100%;
`;

export const CardImage = styled(LazyLoadImage)`
    border-radius: 8px;
    width: 100%;
    height: 100%;
    
    @media screen and (max-width: 600px) {
        width: 75%;
    }
`;
