import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProductReviewFiltersProps {
    tag: string
}

const initialState: ProductReviewFiltersProps = {
    tag: '',
};

export const productReviewFiltersSlice = createSlice({
    name: 'productReviewFilters',
    initialState: initialState,
    reducers: {
        setTagFilter: (state, { payload }: PayloadAction<string>) => {
            state.tag = payload;
        },
    },
});

export const { setTagFilter } = productReviewFiltersSlice.actions;

export default productReviewFiltersSlice.reducer;
