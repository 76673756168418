import { Span, Select } from './styled';
import { useField } from 'formik';

function SelectField({ label, isFooter, ...props }: any) {
    const [field, meta] = useField(props);

    return (
        <>
            {label ? <Span isFooter={isFooter}>{label}</Span> : null}
            <Select
                {...field}
                {...props}
                isFooter={isFooter}
                className={meta.touched && meta.error ? 'input-error' : ''}
            />
            {meta.touched && meta.error && (
                <div className="error-message">{meta.error}</div>
            )}
        </>
    );
}

export default SelectField;
