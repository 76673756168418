import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

export const PeoplePassionContainer = styled.div``;

export const PeoplePassionSubtitle = styled.h2`
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 50px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
`;

export const PeoplePassionGrid = styled.div`
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-areas:
        'first-image first-image second-image third-image'
        'fourth-image fifth-image fifth-image third-image'
        'fourth-image sixth-image seventh-image eighth-image';

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    .first-image {
        grid-area: first-image;
        height: 260px !important;
    }

    .second-image {
        grid-area: second-image;
        height: 260px !important;
    }

    .third-image {
        grid-area: third-image;
        max-width: 450px;
    }

    .fourth-image {
        grid-area: fourth-image;
        max-width: 300px;
    }

    .fifth-image {
        grid-area: fifth-image;

        @media screen and (min-width: 600px) {
            height: 290px !important;
        }
    }

    .sixth-image {
        grid-area: sixth-image;
        height: 230px !important;
    }

    .seventh-image {
        grid-area: seventh-image;
        height: 230px !important;
    }

    .eighth-image {
        grid-area: eighth-image;
        height: 230px !important;
    }

    @media screen and (max-width: 600px) {
        column-gap: 5px;
        row-gap: 5px;
    }
`;

export const PeoplePassionImageWrapper = styled.div``

export const PeoplePassionImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
