export const scrollToSiteSurvey = () => {
    const siteSurvey = window.document.getElementById('site-survey')!;
    window.scrollTo({ top: siteSurvey.offsetTop - 200, behavior: 'smooth' });
};

export const scrollToInstallationServices = () => {
    const installationServices = window.document.getElementById(
        'installation-services'
    )!;
    window.scrollTo({
        top: installationServices.offsetTop - 84,
        behavior: 'smooth',
    });
};

export const scrollToManagedServices = () => {
    const managedServices = window.document.getElementById('managed-services')!;
    window.scrollTo({
        top: managedServices.offsetTop - 84,
        behavior: 'smooth',
    });
};

export const scrollToOurPassion = () => {
    const ourPassion = window.document.getElementById('our-passion')!;
    window.scrollTo({
        top: ourPassion.offsetTop - 84,
        behavior: 'smooth',
    });
};

export const scrollToResources = () => {
    const ourPassion = window.document.getElementById('resources')!;
    window.scrollTo({
        top: ourPassion.offsetTop - 84,
        behavior: 'smooth',
    });
};

export const scrollToCoMarketing = () => {
    const ourPassion = window.document.getElementById('co-marketing')!;
    window.scrollTo({
        top: ourPassion.offsetTop - 84,
        behavior: 'smooth',
    });
};

export const scrollToDemo = () => {
    const ourPassion = window.document.getElementById('demo')!;
    window.scrollTo({
        top: ourPassion.offsetTop - 84,
        behavior: 'smooth',
    });
};

export const scrollToAgentPortal = () => {
    const agentPortal = window.document.getElementById('agent-portal')!;
    window.scrollTo({ top: agentPortal.offsetTop - 200, behavior: 'smooth' });
};

