import { SupportTechContainer, SupportTechTitle } from './styled';
import barcoTech from 'assets/images/tech-barco.png';
import crestronTech from 'assets/images/tech-crestron.png';
import jabraTech from 'assets/images/jabra-tech.png';
import lenovoTech from 'assets/images/lenovo-tech.png';
import logitechTech from 'assets/images/tech-logitech.png';
import neatTech from 'assets/images/neat-tech.png';
import polyTech from 'assets/images/tech-poly.png';
import yealinkTech from 'assets/images/yealink-tech.png';
import christieTech from 'assets/images/tech-christie.png';
import digitalProjectionTech from 'assets/images/tech-digital-projection.png';
import extronTech from 'assets/images/tech-extron.png';
import Slider from 'components/Slider';

const images = [
    {
        img: barcoTech,
        alt: 'barto-tech',
    },
    {
        img: crestronTech,
        alt: 'crestron-tech',
    },
    {
        img: jabraTech,
        alt: 'jabra-tech',
    },
    {
        img: lenovoTech,
        alt: 'lenovo-tech',
    },
    {
        img: logitechTech,
        alt: 'logitech-tech',
    },
    {
        img: neatTech,
        alt: 'neat-tech',
    },
    {
        img: polyTech,
        alt: 'poly-tech',
    },
    {
        img: yealinkTech,
        alt: 'yealink-tech',
    },
    {
        img: christieTech,
        alt: 'christie-tech',
    },
    {
        img: digitalProjectionTech,
        alt: 'digital-projection-tech',
    },
    {
        img: extronTech,
        alt: 'extron-tech',
    },
];

function SupportTech() {
    return (
        <SupportTechContainer className="container">
            <SupportTechTitle>Technologies We Support</SupportTechTitle>
            <Slider
                slidesPerView={4}
                isNavigation={true}
                isPagination={true}
                isAutoplay={true}
                autoPlayConfig={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                data={images}
                card="isTechSupport"
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                }}
                componentClassName="main-slider"
            />
        </SupportTechContainer>
    );
}

export default SupportTech;
