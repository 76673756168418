import { put, takeEvery } from 'redux-saga/effects';
import { featuredProducts } from 'api';
import { getFeaturedProductsFetch, getFeaturedProductsSuccess, getFeaturedProductsFailure } from 'store/states/featuredProducts';

function* fetchFeaturedProducts() {
    try {
        const { data } = yield featuredProducts();
        yield put(getFeaturedProductsSuccess(data));
    } catch (error) {
        yield put(getFeaturedProductsFailure(error));
    }
}

export default function* rootSaga() {
    yield takeEvery(getFeaturedProductsFetch.type, fetchFeaturedProducts);
}
