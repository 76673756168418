import styled from 'styled-components';

interface FooterProps {
    isFooter?: boolean;
}

export const Select = styled.select<FooterProps>`
    padding: 6px 12px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px;
    background: ${(props) => (props.isFooter ? '#326fa399' : `#e0e0e080`)};
    color: ${(props) => (props.isFooter ? '#a3c202' : `#0e4574`)};
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    width: 100%;
    border: none;
    
    .input-error {
        border: 1px solid #fc8181;
    }

    .error-message {
        color: #fc8181;
        padding 0 0 10px 10px;
    }
`;

export const Span = styled.span<FooterProps>`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px;
    color: ${(props) => (props.isFooter ? '#a3c202' : `#0e4574`)};
`;
