import { useState } from 'react';
import {
    ContactUsFormContainer,
    ContactUsFormDevider,
    ContactUsButtonBlock,
    ContactUsButton,
} from './styled';
import { sendEmail } from 'api';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { contactUsSchema } from './helpers/schemas';
import { Input, Select, Textarea } from 'components/CustomFields';

interface ContactUsFormProps {
    isFooter?: boolean;
}

function ContactUsForm({ isFooter }: ContactUsFormProps) {
    const [isLoading, setLoading] = useState(false);

    return (
        <ContactUsFormContainer isFooter={isFooter}>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    howDidYouHearAboutUs: '',
                    message: '',
                }}
                validationSchema={contactUsSchema}
                onSubmit={async (values) => {
                    setLoading(true);
                    try {
                        await sendEmail(values as any);
                        toast.success('Email sent successfully!');
                    } catch (error) {
                        toast.error('Something wrong!');
                    }
                    setLoading(false);
                }}
            >
                {({ isSubmitting }) => (
                    <Form className={!isFooter ? 'split' : ''}>
                        <ContactUsFormDevider isFooter={isFooter}>
                            <Input
                                type="text"
                                label="First Name"
                                id="firstName"
                                name="firstName"
                                placeholder="John"
                                isFooter={isFooter}
                            />

                            <Input
                                type="text"
                                label="Last Name"
                                id="lastName"
                                name="lastName"
                                placeholder="Smith"
                                isFooter={isFooter}
                            />

                            <Input
                                type="text"
                                label="Email"
                                id="email"
                                name="email"
                                placeholder="johnsmith@gmail.com"
                                isFooter={isFooter}
                            />

                            <Input
                                type="text"
                                label="Phone number"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="484 000 0000"
                                isFooter={isFooter}
                            />
                        </ContactUsFormDevider>
                        
                        <ContactUsFormDevider isFooter={isFooter}>
                            <Select
                                type="text"
                                id="howDidYouHearAboutUs"
                                name="howDidYouHearAboutUs"
                                placeholder="484 000 0000"
                                isFooter={isFooter}
                            >
                                <option hidden>How did you hear about us?</option>
                                <option value="Google/Search Engine">
                                    Google/Search Engine
                                </option>
                                <option value="Social Media">Social Media</option>
                                <option value="Email">Email</option>
                                <option value="Blog">Blog</option>
                                <option value="Peer Referral">Peer Referral</option>
                                <option value="Advertisement">Advertisement</option>
                                <option value="Event or Networking Group">
                                    Event or Networking Group
                                </option>
                                <option value="Billboard">Billboard</option>
                                <option value="Other">Other</option>
                            </Select>

                            <Textarea
                                type="text"
                                id="message"
                                name="message"
                                placeholder="Message*"
                                isFooter={isFooter}
                            />

                            <ContactUsButtonBlock>
                                <ContactUsButton
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isLoading ? 'Loading...' : 'Send'}
                                </ContactUsButton>
                            </ContactUsButtonBlock>
                        </ContactUsFormDevider>
                    </Form>
                )}
            </Formik>
        </ContactUsFormContainer>
    );
}

export default ContactUsForm;
