import { LoaderContainer } from './styled';

function Loader() {
    return (
        <LoaderContainer>
            <div className="loader" />
        </LoaderContainer>
    );
}

export default Loader;
