import { useEffect, useState } from 'react';
import {
    HamburgerMenuContainer,
    CircleHamburgerMenuIcon,
    HamburgerMenuIcon,
    MenuWrapper,
    MenuLogoBlock,
    HamburgerLogo,
    MenuContainer,
    MenuBoxes,
    MenuBox,
    MenuBanner,
    MenuTitle,
    MenuSubtitle,
    CircleHamburgerMenuContainer
} from './styled';
import NavBar from 'components/NavBar';
import { useMediaQuery } from 'utils/media-query';
import { useLocation, useNavigate } from 'react-router-dom';
import profoundLogo from 'assets/icons/logo.svg';
import { menuContent } from './helpers/constants';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getFeaturedProductsFetch } from 'store/states'

function HamburgerMenu() {
    const [isOpen, setOpen] = useState(false);
    const isTablet = useMediaQuery('tablet');
    const isMobile = useMediaQuery('mobile');
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { data } = useSelector((state: RootState) => state.featuredProducts);

    useEffect(() => {
        dispatch(getFeaturedProductsFetch())
    }, [dispatch])


    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    menuContent[1].links = data.slice(0, 4).map(item => ({
        link: `/product-reviews/${item.id}`,
        title: `${item.brand} ${item.model}`
      }));

    return (
        <HamburgerMenuContainer>
            <HamburgerMenuIcon isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
                <div className="bar1" />
                <div className="bar2" />
                <div className="bar3" />
            </HamburgerMenuIcon>
            {isOpen ? (
                <MenuWrapper>
                    <MenuLogoBlock>
                        <HamburgerLogo
                            src={profoundLogo}
                            alt="profound-white-logo"
                            onClick={() => navigate('/')}
                            loading="lazy"
                        />
                    </MenuLogoBlock>

                    <MenuContainer>
                        {!isTablet ? null : <NavBar />}
                        <MenuBoxes>
                            {menuContent.map((item) => (
                                <MenuBox key={item.id}>
                                    <MenuBanner
                                        src={item.banner}
                                        placeholderSrc={item.background}
                                        alt={item.alt}
                                        loading="lazy"
                                        width={'100%'}
                                        height={isMobile ? '150px' : '260px'}
                                    />
                                    <MenuTitle>{item.title}</MenuTitle>
                                    {item.links.map((link: any, index: any) => (
                                        <MenuSubtitle
                                            key={index}
                                            to={link.link}
                                            className={`${
                                                link.isExternal && 'is-external'
                                            }`}
                                            target={link.isExternal ? '_blank' : ''}
                                        >
                                            {link.title}
                                        </MenuSubtitle>
                                    ))}
                                </MenuBox>
                            ))}
                        </MenuBoxes>
                        
                        <CircleHamburgerMenuContainer>
                            <CircleHamburgerMenuIcon isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
                                <div className="bar1" />
                                <div className="bar2" />
                                <div className="bar3" />
                            </CircleHamburgerMenuIcon>
                        </CircleHamburgerMenuContainer>
                    </MenuContainer>
                </MenuWrapper>
            ) : null}
        </HamburgerMenuContainer>
    );
}

export default HamburgerMenu;
