import { useLocation } from 'react-router-dom';
import {
    SubHeaderBackground,
    SubHeaderContainer,
    SubHeaderLink,
    SubHeaderButton,
    SubHeaderTitle,
} from './styled';
import { subMenuItems } from 'global/globalState';
import { SubmenuProps } from 'global/globalTypes';
import Slider from 'components/Slider';

function SubHeader() {
    const location = useLocation();
    const path = location.pathname;
    const isNotHomePage = path !== '/';
    const isProductReviews = path === '/product-reviews' || path === '/product-reviews/';
    const shouldSubHeaderBeShown =
        Object.keys(subMenuItems).includes(path) && isNotHomePage;

    return shouldSubHeaderBeShown ? (
        <SubHeaderBackground>
            <SubHeaderContainer className='container'>
                {isProductReviews ? 
                    <Slider
                        componentClassName="subheader"
                        slidesPerView={1}
                        isNavigation={true}
                        isPagination={false}
                        card="isSubHeader"
                        data={subMenuItems[path]}
                        breakpoints={{
                            300: {
                                slidesPerView: 3,
                            },
                            500: {
                                slidesPerView: 3.5,
                            },
                            550: {
                                slidesPerView: 4,
                            },
                            700: {
                                slidesPerView: 5,
                            },
                            900: {
                                slidesPerView: 6,
                            },
                            1000: {
                                slidesPerView: 7,
                            },
                            1150: {
                                slidesPerView: 8,
                            },
                            1200: {
                                slidesPerView: 9
                            }
                        }}
                    />
                :
                    subMenuItems[path].map((item: SubmenuProps, index: number) =>
                        item.url ? (
                            <SubHeaderLink
                                key={index}
                                to={item.url}
                                target={item.isExternal ? '_blank' : '_self'}
                                isDisabled={item.isDisabled}
                            >
                                <img src={item.img} alt="qweq-we" loading="lazy" />
                                <SubHeaderTitle>{item.title}</SubHeaderTitle>
                            </SubHeaderLink>
                        ) : (
                            <SubHeaderButton
                                key={index}
                                onClick={item.onClick}
                                isDisabled={item.isDisabled}
                            >
                                <img src={item.img} alt="qweq-we" loading="lazy" />
                                <SubHeaderTitle>{item.title}</SubHeaderTitle>
                            </SubHeaderButton>
                        )
                    )
                }
            </SubHeaderContainer>
        </SubHeaderBackground>
    ) : null;
}

export default SubHeader;
