import { InputContainer, Span, Input } from './styled';
import { useRef } from 'react';
import { useField } from 'formik';

function InputField({ label, isFooter, ...props }: any) {
    const [field, meta] = useField(props);
    const inputRef = useRef<HTMLInputElement | null>(null) 

    const removePlaceholder = () => {
        if (inputRef.current) {
            inputRef.current.placeholder = '';
        }
    }

    return (
        <>
            <InputContainer
                isFooter={isFooter}
                className={meta.touched && meta.error ? 'input-error' : ''}
            >
                <Span isFooter={isFooter}>{label}</Span>
                <Input ref={inputRef} {...field} {...props} isFooter={isFooter} onFocus={removePlaceholder} />
            </InputContainer>
            {meta.touched && meta.error && (
                <div className="error-message">{meta.error}</div>
            )}
        </>
    );
}

export default InputField;
