import blogBanner from '../assets/blog-menu.png';
import smallBlogBanner from '../assets/small_blog-menu.png';
import productReviewBanner from '../assets/product-review-menu.png';
import smallProductReviewBanner from '../assets/small_product-review-menu.png';
import designHub from 'assets/images/design-hub.jpg';
import servicesHub from 'assets/images/services-hub.jpg';
import smallDesignHub from 'assets/images/small_design-hub.jpg';
import smallServicesHub from 'assets/images/small_services-hub.jpg';

export const menuContent = [
    {
        id: 1,
        banner: blogBanner,
        background: smallBlogBanner,
        alt: 'blog-banner',
        title: 'Blog',
        links: [
            {
                title: 'Top Video Conferencing Cameras',
                link: 'https://blog.profound-tech.com/top-video-conferencingcameras',
                isExternal: true,
            },
            {
                title: 'Market Analysis and Predictions for 2023',
                link: 'https://blog.profound-tech.com/predictions-2023-videoconferencing',
                isExternal: true,
            },
            {
                title: 'Tips for Selecting the Right AV Equipment',
                link: 'https://blog.profound-tech.com/selecting-av-zoom',
                isExternal: true,
            },
            {
                title: 'Techniques Remote Rooms',
                link: 'https://blog.profound-tech.com/techniques-remote-rooms',
                isExternal: true,
            },
        ],
    },
    {
        id: 2,
        banner: productReviewBanner,
        background: smallProductReviewBanner,
        alt: 'product-review-banner',
        title: 'Product Review',
        links: [
            {
                title: 'Coming Soon',
                link: '',
            },
        ],
    },
    {
        id: 3,
        banner: designHub,
        background: smallDesignHub,
        alt: 'design-hub-banner',
        title: 'Design Hub',
        links: [
            {
                title: 'Case Studies',
                link: '/downloads',
            },
            {
                title: 'Room Builder',
                link: '/room-builder',
            },
        ],
    },
    {
        id: 4,
        banner: servicesHub,
        title: 'Services Hub',
        background: smallServicesHub,
        alt: 'service-hub-banner',
        links: [
            {
                title: 'Installation Services',
                link: '/services-hub#installation-services',
            },
            {
                title: 'Managed Services',
                link: '/services-hub#managed-services',
            },
        ],
    },
];
