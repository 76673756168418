import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoomSurveyProps {
    step: number,
    email: {
        value: string,
        error: boolean
    },
    contactName: {
        value: string,
        error: boolean
    },
    roomName: {
        value: string,
        error: boolean
    },
    countRoom: {
        value: number,
        error: boolean
    },

    roomDimensions: {
        value: string,
        error: boolean
    },
    wallDimensions: {
        value: string,
        error: boolean
    },
    displayCameraIsMounted: {
        value: string,
        error: boolean
    },
    ceilingMaterial: {
        value: string,
        error: boolean
    },
    ceilingDeckHeight: {
        value: string,
        error: boolean
    },
    floorCabling: {
        value: string,
        error: boolean
    },

    atTheTableOptions: {
        value: string | any,
        error: boolean
    },
    byodAtTheTable: {
        value: string,
        error: boolean
    },
    additionalMicrophonesDesired: {
        value: string,
        error: boolean
    },

    platformUsedInThisRoom: {
        value: string | any,
        error: boolean
    },
    schedulingPanel: {
        value: string,
        error: boolean
    },

    acPowerAcknowledgement: {
        value: string,
        error: boolean
    },
    customerResponsibleAcknowledgement: {
        value: string,
        error: boolean
    },
    floorCoringAcknowledgement: {
        value: string,
        error: boolean
    },
    interoperabilityAcknowledgement: {
        value: string,
        error: boolean
    },
    customerProvidedEquipmentAcknowledgement: {
        value: string,
        error: boolean
    },

    additionInformation: string,

}

const initialState: RoomSurveyProps = {
    step: 1,
    email: {
        value: '',
        error: false
    },
    contactName: {
        value: '',
        error: false
    },
    roomName: {
        value: '',
        error: false
    },
    countRoom: {
        value: 0,
        error: false
    },

    roomDimensions: {
        value: '',
        error: false
    },
    wallDimensions: {
        value: '',
        error: false
    },
    displayCameraIsMounted: {
        value: '',
        error: false
    },
    ceilingMaterial: {
        value: '',
        error: false
    },
    ceilingDeckHeight: {
        value: '',
        error: false
    },
    floorCabling: {
        value: '',
        error: false
    },

    atTheTableOptions: {
        value: '',
        error: false
    },
    byodAtTheTable: {
        value: '',
        error: false
    },
    additionalMicrophonesDesired: {
        value: '',
        error: false
    },

    platformUsedInThisRoom: {
        value: '',
        error: false
    },
    schedulingPanel: {
        value: '',
        error: false
    },
    
    acPowerAcknowledgement: {
        value: '',
        error: false
    },
    customerResponsibleAcknowledgement: {
        value: '',
        error: false
    },
    floorCoringAcknowledgement: {
        value: '',
        error: false
    },
    interoperabilityAcknowledgement: {
        value: '',
        error: false
    },
    customerProvidedEquipmentAcknowledgement: {
        value: '',
        error: false
    },

    additionInformation: ''
};

export const roomSurveySlice = createSlice({
    name: 'roomSurvey',
    initialState: initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<number>) => {
            state.step = payload;
        },
        setEmailValue: (state, { payload }: PayloadAction<string>) => {
            state.email.value = payload;
            state.email.error = false;
        },
        setEmailError: (state, { payload }: PayloadAction<boolean>) => {
            state.email.error = payload;
        },
        setContactNameValue: (state, { payload }: PayloadAction<string>) => {
            state.contactName.value = payload;
            state.contactName.error = false;
        },
        setContactNameError: (state, { payload }: PayloadAction<boolean>) => {
            state.contactName.error = payload;
        },
        setRoomNameValue: (state, { payload }: PayloadAction<string>) => {
            state.roomName.value = payload;
            state.roomName.error = false;
        },
        setRoomNameError: (state, { payload }: PayloadAction<boolean>) => {
            state.roomName.error = payload;
        },
        setCountRoomValue: (state, { payload }: PayloadAction<number>) => {
            state.countRoom.value = payload;
            state.countRoom.error = false;
        },
        setCountRoomError: (state, { payload }: PayloadAction<boolean>) => {
            state.countRoom.error = payload;
        },
        
        setRoomDimensionsValue: (state, { payload }: PayloadAction<string>) => {
            state.roomDimensions.value = payload;
            state.roomDimensions.error = false;
        },
        setRoomDimensionsError: (state, { payload }: PayloadAction<boolean>) => {
            state.roomDimensions.error = payload;
        },
        setWallDimensionsValue: (state, { payload }: PayloadAction<string>) => {
            state.wallDimensions.value = payload;
            state.wallDimensions.error = false;
        },
        setWallDimensionsError: (state, { payload }: PayloadAction<boolean>) => {
            state.wallDimensions.error = payload;
        },
        setDisplayCameraIsMountedValue: (state, { payload }: PayloadAction<string>) => {
            state.displayCameraIsMounted.value = payload;
            state.displayCameraIsMounted.error = false;
        },
        setDisplayCameraIsMountedError: (state, { payload }: PayloadAction<boolean>) => {
            state.displayCameraIsMounted.error = payload;
        },
        setCeilingMaterialValue: (state, { payload }: PayloadAction<string>) => {
            state.ceilingMaterial.value = payload;
            state.ceilingMaterial.error = false;
        },
        setCeilingMaterialError: (state, { payload }: PayloadAction<boolean>) => {
            state.ceilingMaterial.error = payload;
        },
        setCeilingDeckHeightValue: (state, { payload }: PayloadAction<string>) => {
            state.ceilingDeckHeight.value = payload;
            state.ceilingDeckHeight.error = false;
        },
        setCeilingDeckHeightError: (state, { payload }: PayloadAction<boolean>) => {
            state.ceilingDeckHeight.error = payload;
        },
        setFloorCablingValue: (state, { payload }: PayloadAction<string>) => {
            state.floorCabling.value = payload;
            state.floorCabling.error = false;
        },
        setFloorCablingError: (state, { payload }: PayloadAction<boolean>) => {
            state.floorCabling.error = payload;
        },

        setAtTheTableOptionsValue: (state, { payload }: PayloadAction<string>) => {
            state.atTheTableOptions.value = payload;
            state.atTheTableOptions.error = false;
        },
        setAtTheTableOptionsError: (state, { payload }: PayloadAction<boolean>) => {
            state.atTheTableOptions.error = payload;
        },
        setByodAtTheTableValue: (state, { payload }: PayloadAction<string>) => {
            state.byodAtTheTable.value = payload;
            state.byodAtTheTable.error = false;
        },
        setByodAtTheTableError: (state, { payload }: PayloadAction<boolean>) => {
            state.byodAtTheTable.error = payload;
        },
        setAdditionalMicrophonesDesiredValue: (state, { payload }: PayloadAction<string>) => {
            state.additionalMicrophonesDesired.value = payload;
            state.additionalMicrophonesDesired.error = false;
        },
        setAdditionalMicrophonesDesiredError: (state, { payload }: PayloadAction<boolean>) => {
            state.additionalMicrophonesDesired.error = payload;
        },
        
        setPlatformUsedInThisRoomValue: (state, { payload }: PayloadAction<string>) => {
            state.platformUsedInThisRoom.value = payload;
            state.platformUsedInThisRoom.error = false;
        },
        setPlatformUsedInThisRoomError: (state, { payload }: PayloadAction<boolean>) => {
            state.platformUsedInThisRoom.error = payload;
        },
        setSchedulingPanelValue: (state, { payload }: PayloadAction<string>) => {
            state.schedulingPanel.value = payload;
            state.schedulingPanel.error = false;
        },
        setSchedulingPanelError: (state, { payload }: PayloadAction<boolean>) => {
            state.schedulingPanel.error = payload;
        },
        
        setACPowerAcknowledgementValue: (state, { payload }: PayloadAction<string>) => {
            state.acPowerAcknowledgement.value = payload;
            state.acPowerAcknowledgement.error = false;
        },
        setACPowerAcknowledgementError: (state, { payload }: PayloadAction<boolean>) => {
            state.acPowerAcknowledgement.error = payload;
        },
        setCustomerResponsibleAcknowledgementValue: (state, { payload }: PayloadAction<string>) => {
            state.customerResponsibleAcknowledgement.value = payload;
            state.customerResponsibleAcknowledgement.error = false;
        },
        setCustomerResponsibleAcknowledgementError: (state, { payload }: PayloadAction<boolean>) => {
            state.customerResponsibleAcknowledgement.error = payload;
        },
        setFloorCoringAcknowledgementValue: (state, { payload }: PayloadAction<string>) => {
            state.floorCoringAcknowledgement.value = payload;
            state.floorCoringAcknowledgement.error = false;
        },
        setFloorCoringAcknowledgementError: (state, { payload }: PayloadAction<boolean>) => {
            state.floorCoringAcknowledgement.error = payload;
        },
        setInteroperabilityAcknowledgementValue: (state, { payload }: PayloadAction<string>) => {
            state.interoperabilityAcknowledgement.value = payload;
            state.interoperabilityAcknowledgement.error = false;
        },
        setInteroperabilityAcknowledgementError: (state, { payload }: PayloadAction<boolean>) => {
            state.interoperabilityAcknowledgement.error = payload;
        },
        setCustomerProvidedEquipmentAcknowledgementValue: (state, { payload }: PayloadAction<string>) => {
            state.customerProvidedEquipmentAcknowledgement.value = payload;
            state.customerProvidedEquipmentAcknowledgement.error = false;
        },
        setCustomerProvidedEquipmentAcknowledgementError: (state, { payload }: PayloadAction<boolean>) => {
            state.customerProvidedEquipmentAcknowledgement.error = payload;
        },
    
        setAdditionInformation: (state, { payload }: PayloadAction<string>) => {
            state.additionInformation = payload;
        },
    
        setResetData: (state) => {
            state.step = 1
            state.email.value = ''
            state.contactName.value = ''
            state.roomName.value = ''
            state.countRoom.value = 0
            state.roomDimensions.value = ''
            state.wallDimensions.value = ''
            state.displayCameraIsMounted.value = ''
            state.ceilingMaterial.value = ''
            state.ceilingDeckHeight.value = ''
            state.floorCabling.value = ''
            state.atTheTableOptions.value = ''
            state.byodAtTheTable.value = ''
            state.additionalMicrophonesDesired.value = ''
            state.platformUsedInThisRoom.value = ''
            state.schedulingPanel.value = ''
            state.acPowerAcknowledgement.value = ''
            state.customerResponsibleAcknowledgement.value = ''
            state.floorCoringAcknowledgement.value = ''
            state.interoperabilityAcknowledgement.value = ''
            state.customerProvidedEquipmentAcknowledgement.value = ''
            state.additionInformation = ''
        },
    },
});

export const { 
    setStep, 
    setEmailValue, setEmailError, 
    setContactNameValue, setContactNameError, 
    setRoomNameValue, setRoomNameError, 
    setCountRoomValue, setCountRoomError,
    setRoomDimensionsValue, setRoomDimensionsError, 
    setWallDimensionsValue, setWallDimensionsError, 
    setDisplayCameraIsMountedValue, setDisplayCameraIsMountedError,
    setCeilingMaterialValue, setCeilingMaterialError, 
    setCeilingDeckHeightValue, setCeilingDeckHeightError, 
    setFloorCablingValue, setFloorCablingError,
    setAtTheTableOptionsValue, setAtTheTableOptionsError, 
    setByodAtTheTableValue, setByodAtTheTableError, 
    setAdditionalMicrophonesDesiredValue, setAdditionalMicrophonesDesiredError,
    setPlatformUsedInThisRoomValue, setPlatformUsedInThisRoomError, 
    setSchedulingPanelValue, setSchedulingPanelError, 
    setACPowerAcknowledgementValue, setACPowerAcknowledgementError, 
    setCustomerResponsibleAcknowledgementValue, setCustomerResponsibleAcknowledgementError, 
    setFloorCoringAcknowledgementValue, setFloorCoringAcknowledgementError, 
    setInteroperabilityAcknowledgementValue, setInteroperabilityAcknowledgementError, 
    setCustomerProvidedEquipmentAcknowledgementValue, setCustomerProvidedEquipmentAcknowledgementError, 
    setAdditionInformation, setResetData
} = roomSurveySlice.actions;

export default roomSurveySlice.reducer;
