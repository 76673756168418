import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { 
    AuthModalContainer, AuthModalImage, AuthModalContent, 
    AuthModalInputBlock, AuthModalInputLabel, AuthModalInput,
    AuthModalButton, AuthModalText, AuthModalErrors, AuthModalLink 
} from './styled'
import auth from './assets/auth.png'

import { checkIfTokenValid, setToken } from 'utils/token';
import { validateEmail } from 'utils/validateEmail';

import { signInFetch, signUpFetch, signUpSuccess, signInFailure, setUser } from 'store/states'
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { signInSuccess } from 'store/states/auth';

function AuthModal() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [code, setCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isSignIn, setSignIn] = useState(true)
    const [contentStep, setContentStep] = useState('auth')

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isTokenValid = checkIfTokenValid();

    const { 
        isSignUpSuccess, isSignInLoading, signInError, 
        isSignUpLoading, signUpError
    } = useSelector((state: RootState) => state.auth);
    const user = useSelector((state: RootState) => state.user);
    
    useEffect(() => {
        console.log(isTokenValid)
        if (isTokenValid) navigate('/agent')
    }, [user, isTokenValid, navigate])

    const signUpWithSignIn = async () => {
        await dispatch(signUpFetch({ email, username: email.split('@')[0], password }))
        if (isSignUpSuccess) {
            setTimeout(() => {
                // temporary
                setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA0Mzk2NjExLCJpYXQiOjE3MDQzOTYwMTEsImp0aSI6ImNiZmY0NDlhYmRhYjRmNTk5YzM4NzFkZWY2MDg3ZGYwIiwidXNlcl9pZCI6M30.3SSwMXvw-q_EaY8mlPfLD1nBMqeRkGgiMwMJZvF6KYk')
                localStorage.setItem('user', JSON.stringify({ temporary: 'temporary' }))
                dispatch(setUser({ temporary: 'temporary' }))
                dispatch(signInSuccess())
                navigate('/agent')
            }
                // dispatch(signInFetch({ username: email.split('@')[0], password }))
            , 500)
        }
        dispatch(signUpSuccess(false))
    }

    const signInValidation = () => {
        if (validateEmail(email)) {
            // temporary
            setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA0Mzk2NjExLCJpYXQiOjE3MDQzOTYwMTEsImp0aSI6ImNiZmY0NDlhYmRhYjRmNTk5YzM4NzFkZWY2MDg3ZGYwIiwidXNlcl9pZCI6M30.3SSwMXvw-q_EaY8mlPfLD1nBMqeRkGgiMwMJZvF6KYk')
            localStorage.setItem('user', JSON.stringify({ temporary: 'temporary' }))
            dispatch(setUser({ temporary: 'temporary' }))
            dispatch(signInSuccess())
            navigate('/agent')
        }
        //  dispatch(signInFetch({ username: email.split('@')[0], password }))
        else dispatch(signInFailure({ response: { data: 'Email is invalid!'}}))
    }

    const modalContent = () => {
        switch (contentStep) {
            case 'auth': 
                return (
                    <>
                        <AuthModalInputBlock>
                            <AuthModalInputLabel>Email</AuthModalInputLabel>
                            <AuthModalInput 
                                type="text" 
                                value={email} 
                                onChange={(e: any) => setEmail(e.target.value)} 
                                placeholder='Your email'
                            />
                        </AuthModalInputBlock>
                        <AuthModalInputBlock>
                            <AuthModalInputLabel>Password</AuthModalInputLabel>
                            <AuthModalInput 
                                type="password" 
                                value={password} 
                                onChange={(e: any) => setPassword(e.target.value)} 
                                placeholder='Your password'
                            />
                            <AuthModalLink onClick={() => setContentStep('sendCode')}>Forgot your password</AuthModalLink>
                        </AuthModalInputBlock>

                        <AuthModalErrors>
                            {isSignIn
                                ? signInError?.response?.data ? 
                                    JSON.stringify(signInError.response.data) 
                                    : null
                                : signUpError?.response?.data ? 
                                    JSON.stringify(signUpError.response.data) 
                                    : null
                            }
                        </AuthModalErrors>

                        <AuthModalButton 
                            onClick={() => isSignIn ? signInValidation() : signUpWithSignIn()}
                            disabled={isSignInLoading || isSignUpLoading}
                        >
                            {isSignInLoading || isSignUpLoading ? 'Loading...' : isSignIn ? 'Login' : 'Register'}
                        </AuthModalButton>
                        {isSignIn ? 
                            <AuthModalText>
                                You don't have an account?
                                <AuthModalLink onClick={() => setSignIn(false)}>Sign up</AuthModalLink>
                            </AuthModalText>
                        : <AuthModalText>
                            Do you already have an account?
                            <AuthModalLink onClick={() => setSignIn(true)}>Sign in</AuthModalLink>
                        </AuthModalText>}
                    </>
                )
                
            case 'sendCode':
                return (
                    <>
                        <AuthModalInputBlock>
                            <AuthModalInputLabel>Email</AuthModalInputLabel>
                            <AuthModalInput 
                                type="text" 
                                value={email} 
                                onChange={(e: any) => setEmail(e.target.value)} 
                                placeholder='Your email'
                            />
                        </AuthModalInputBlock>

                        <AuthModalButton onClick={() => setContentStep('checkCode')}>
                            Send code
                        </AuthModalButton>
                    </>
                )

            case 'checkCode':
                return (
                    <>
                        <AuthModalInputBlock>
                            <AuthModalInputLabel>Code</AuthModalInputLabel>
                            <AuthModalInput 
                                type="text" 
                                value={code} 
                                onChange={(e: any) => setCode(e.target.value)} 
                                placeholder='Enter code'
                            />
                        </AuthModalInputBlock>

                        <AuthModalButton onClick={() => setContentStep('newPassword')}>
                            Verify code
                        </AuthModalButton>
                    </>
                )

            case 'newPassword':
                return (
                    <>
                        <AuthModalInputBlock>
                            <AuthModalInputLabel>New password</AuthModalInputLabel>
                            <AuthModalInput 
                                type="password" 
                                value={newPassword} 
                                onChange={(e: any) => setNewPassword(e.target.value)} 
                                placeholder='Enter password'
                            />
                        </AuthModalInputBlock>
                        
                        <AuthModalInputBlock>
                            <AuthModalInputLabel>Confirm password</AuthModalInputLabel>
                            <AuthModalInput 
                                type="password" 
                                value={confirmPassword} 
                                onChange={(e: any) => setConfirmPassword(e.target.value)} 
                                placeholder='Enter password again'
                            />
                        </AuthModalInputBlock>

                        <AuthModalButton onClick={() => console.log('change the password, login and navigate to the agent page')}>
                            Change password
                        </AuthModalButton>
                    </>
                )
        }
    }

    return (
        <AuthModalContainer>
            <AuthModalImage src={auth} />

            <AuthModalContent>
                {modalContent()}
            </AuthModalContent>
        </AuthModalContainer>
    )
}

export default AuthModal