import styled from 'styled-components';

interface HeaderContainerProps {
    background: string;
}

export const HeaderWrapper = styled.div<HeaderContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${(props) => props.background};
    z-index: 2;
`;

export const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px !important;
    color: #ababab;
`;

export const HeaderImage = styled.img`
    width: 180px;
    cursor: pointer;
`;

export const PreviousPage = styled.p`
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    img {
        width: 32px;
    }

    @media screen and (max-width: 600px) {
        margin-left: 30px;
    }
`;