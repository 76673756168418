// export const baseApiURL = process.env.BACKEND_URL || 'http://localhost:8000';

export const endpoints = {
    sendEmail: `/send-email/`,
    subscribe: `/subscribe/`,
    products: (labelId?: string, page?: string) => `/products${labelId ? `/${labelId}/` : ''}${page ? `?page=${page}` : ''}`,
    featuredProducts: `/featured-products`,
    surveyRoom: `/survey-room/`,
    signUp: `/sign-up/`,
    signIn: `/sign-in/`,
    tokenRefresh: `/token/refresh/`,
};
