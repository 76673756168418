import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper';
import { SliderContainer } from './styled';
import ZoomImage from '../ZoomImage'
import { getExactCard } from './helpers';

// Import Swiper styles
import './style.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

interface SliderProps<T> {
    id?: string;
    slidesPerView?: number;
    isNavigation?: boolean;
    isPagination?: boolean;
    data: T;
    card: string;
    breakpoints?: any;
    isDynamicBullets?: boolean;
    isVertical?: boolean;
    isScrollbar?: boolean;
    isAutoplay?: boolean;
    componentClassName?: string;
    withoutContainer?: boolean;
    effect?: string;
    coverflowEffect?: any,
    isCenteredSlides?: boolean
    autoPlayConfig?: any
    isCustomPagination?: boolean
}

function Slider({
    id,
    slidesPerView,
    isNavigation,
    isPagination,
    data,
    card,
    breakpoints,
    isDynamicBullets,
    isVertical,
    isAutoplay,
    componentClassName,
    withoutContainer,
    effect,
    coverflowEffect,
    isCenteredSlides,
    autoPlayConfig,
    isCustomPagination
}: SliderProps<any>) {
    const [showZoomImageModal, setShowZoomImageModal] = useState(false)
    const [imageUri, setImageUri] = useState('')

    const showZoomImage = (imageUri: string) => {
        setImageUri(imageUri)
        setShowZoomImageModal(true)
    }

    const hideZoomImage = () => {
        setImageUri('')
        setShowZoomImageModal(false)
    }

    const sliderModules = [];
    let paginationSettings = {};
    let autoPlaySettings = {};

    isNavigation && sliderModules.push(Navigation);

    if (isPagination) {
        sliderModules.push(Pagination);
        paginationSettings = {
            clickable: isPagination,
        };
    }

    effect === 'coverflow' && sliderModules.push(EffectCoverflow)

    if (isCustomPagination) {
        
        paginationSettings = {
            ...paginationSettings,
            dynamicBullets: false,
            renderBullet: function (index: number, className: string) {
              return '<span class="' + className + '">' + (index + 1) + '</span>';
            },
        };
    }

    if (isDynamicBullets) {
        paginationSettings = {
            ...paginationSettings,
            dynamicBullets: isDynamicBullets,
            renderBullet: function (index: number, className: string) {
                const years = [
                    2001,
                    '2002-2008',
                    2008,
                    2009,
                    2010,
                    2011,
                    2012,
                    2013,
                    '2013-2014',
                    2014,
                    2015,
                    2016,
                    2017,
                    '2017-2018',
                    2018,
                    2019,
                    2021,
                ];
                return (
                    '<span class="' +
                    className +
                    '">' +
                    years[index] +
                    '</span>'
                );
            },
        };
    }

    if (isAutoplay) {
        sliderModules.push(Autoplay);
        autoPlaySettings = {...autoPlayConfig}
    }

    return (
        <SliderContainer className={`${!withoutContainer && 'container'} ${componentClassName}`}>
            <Swiper
                id={id}
                spaceBetween={50}
                modules={sliderModules}
                breakpoints={breakpoints}
                slidesPerView={slidesPerView}
                pagination={paginationSettings}
                direction={isVertical ? 'vertical' : 'horizontal'}
                autoplay={autoPlaySettings}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                effect={effect}
                centeredSlides={isCenteredSlides}
                coverflowEffect={coverflowEffect}
                lazyPreloadPrevNext={100}
            >
                {data.map((item: any, index: any) => (
                    <SwiperSlide key={index}>
                        {getExactCard(card, item, showZoomImage) as any}
                    </SwiperSlide>
                ))}
            </Swiper>
            {isNavigation ? (
                <>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </>
            ) : null}
            
            <ZoomImage 
                isOpen={showZoomImageModal} 
                imageUri={imageUri}
                closeModal={hideZoomImage} 
            />
        </SliderContainer>
    );
}

export default Slider;
