import styled from 'styled-components';

export const LoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -5%);

    .loader {
        border: 5px solid #f3f3f3; /* Light grey */
        border-top: 5px solid #4f7fa7; /* Blue */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
